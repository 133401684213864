import { template as template_cb8016d11d8a4ddabeae44dfc75897a0 } from "@ember/template-compiler";
const FKText = template_cb8016d11d8a4ddabeae44dfc75897a0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
