import { template as template_86c31a80489049519d063fd8296ac45d } from "@ember/template-compiler";
const WelcomeHeader = template_86c31a80489049519d063fd8296ac45d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
