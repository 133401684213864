import { template as template_55a2a240968d41b4842fa407efabec04 } from "@ember/template-compiler";
const FKControlMenuContainer = template_55a2a240968d41b4842fa407efabec04(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
